/* A custom class that wraps a FormKit Component to add customization towards slots and custom
classes. */

<script lang="ts" setup>
import { initTooltips } from 'flowbite'
import { computed, onMounted, useAttrs } from 'vue'
const { t } = useI18n()
const attrs = useAttrs()

const required = computed(() => {
  // Deduce required or not from validation rules
  return attrs.validation && (attrs.validation as string).includes('required')
})
onMounted(() => {
  // Initialize tooltips
  initTooltips()
})
</script>

<template>
  <!-- Pass all props to FormKit -->
  <FormKit
    v-bind="$attrs"
    :validation-messages="{
      // https://github.com/formkit/formkit/blob/c781b3995d3873cdf8ffd0cc30008aa8072a6935/packages/i18n/src/locales/en.ts
      required: ({ name }: any) => {
        return `${name} ${t('common.form.error.is-required')}`
      },
      url: () => {
        return t('common.form.error.invalid-url')
      },
      youtube: () => {
        return t('common.form.error.invalid-youtube-channel')
      },
      youtubeVideo: () => {
        return t('common.form.error.invalid-youtube-cover-video')
      },
      instagram: () => {
        return t('common.form.error.invalid-instagram')
      },
      facebook: () => {
        return t('common.form.error.invalid-facebook')
      },
      linkedin: () => {
        return t('common.form.error.invalid-linkedin')
      },
      email: () => {
        return t('common.form.error.invalid-email')
      },
      length: ({ name, args }: any) => {
        return `${name} ${t('common.form.error.at-least-char', { n: args[0] })}`
      },
      confirm: ({ name }: any) => {
        return `${name}  ${t('common.form.error.does-not-match')}`
      },
      contains_numeric: ({ name }: any) => {
        return `${name} ${t('common.form.error.must-contain-number')}`
      },
      contains_alpha: ({ name }: any) => {
        return `${name} ${t('common.form.error.must-contain-letter')}`
      },
      contains_symbol: ({ name }: any) => {
        return `${name} ${t('common.form.error.must-contain-symbol')}`
      },
      requiredphone({ name }: any) {
        return `${name} ${t('common.form.error.is-required')}`
      },
      tlphone: () => {
        return t('common.form.error.invalid-phone-number')
      }
    }"
    :validation-rules="{
      youtube: (node: any) => {
        return Boolean(
          node.value &&
            typeof node.value === 'string' &&
            node.value.startsWith('https://www.youtube.com/')
        )
      },
      youtubeVideo: (node: any) => {
        return Boolean(
          node.value &&
            typeof node.value === 'string' &&
            node.value.startsWith('https://www.youtube.com/watch?v=')
        )
      },
      instagram: (node: any) => {
        return Boolean(
          node.value &&
            typeof node.value === 'string' &&
            node.value.startsWith('https://www.instagram.com/')
        )
      },
      linkedin: (node: any) => {
        return Boolean(
          node.value &&
            typeof node.value === 'string' &&
            node.value.startsWith('https://www.linkedin.com/')
        )
      },
      facebook: (node: any) => {
        return Boolean(
          node.value &&
            typeof node.value === 'string' &&
            node.value.startsWith('https://www.facebook.com/')
        )
      },
      requiredphone: (node: any) => {
        // @ts-ignore
        if (!node.value || (node.value && !node.value.value)) return false // handle empty value
        return true
      },
      tlphone: (node: any) => {
        // @ts-ignore
        if (!node.value || (node.value && !node.value.value)) return true // handle empty value
        return Boolean(
          // @ts-ignore
          node.value && node.value.isValid
        )
      }
    }"
  >
    <template #label="context">
      <div
        v-if="context.label"
        class="block text-h5 font-medium text-primary dark:text-white"
        :class="{
          'mb-[1.25rem]': $attrs.type !== 'checkbox' && $attrs.type !== 'radio'
        }"
      >
        {{ context.label }}
        <template v-if="$attrs.type !== 'checkbox' && $attrs.type !== 'radio'">
          <span v-if="required" class="text-red-40">*</span>
          <span v-else class="text-t5 font-light text-gray-50">(optional)</span>
        </template>
      </div>
    </template>
    <template v-if="$attrs.tooltip" #suffixIcon>
      <div class="px-[0.635rem]">
        <div :data-tooltip-target="`tooltip-${$attrs.name}`" class="cursor-pointer">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M12 17C12.2833 17 12.5208 16.9042 12.7125 16.7125C12.9042 16.5208 13 16.2833 13 16V12C13 11.7167 12.9042 11.4792 12.7125 11.2875C12.5208 11.0958 12.2833 11 12 11C11.7167 11 11.4792 11.0958 11.2875 11.2875C11.0958 11.4792 11 11.7167 11 12V16C11 16.2833 11.0958 16.5208 11.2875 16.7125C11.4792 16.9042 11.7167 17 12 17ZM12 9C12.2833 9 12.5208 8.90417 12.7125 8.7125C12.9042 8.52083 13 8.28333 13 8C13 7.71667 12.9042 7.47917 12.7125 7.2875C12.5208 7.09583 12.2833 7 12 7C11.7167 7 11.4792 7.09583 11.2875 7.2875C11.0958 7.47917 11 7.71667 11 8C11 8.28333 11.0958 8.52083 11.2875 8.7125C11.4792 8.90417 11.7167 9 12 9ZM12 22C10.6167 22 9.31667 21.7375 8.1 21.2125C6.88333 20.6875 5.825 19.975 4.925 19.075C4.025 18.175 3.3125 17.1167 2.7875 15.9C2.2625 14.6833 2 13.3833 2 12C2 10.6167 2.2625 9.31667 2.7875 8.1C3.3125 6.88333 4.025 5.825 4.925 4.925C5.825 4.025 6.88333 3.3125 8.1 2.7875C9.31667 2.2625 10.6167 2 12 2C13.3833 2 14.6833 2.2625 15.9 2.7875C17.1167 3.3125 18.175 4.025 19.075 4.925C19.975 5.825 20.6875 6.88333 21.2125 8.1C21.7375 9.31667 22 10.6167 22 12C22 13.3833 21.7375 14.6833 21.2125 15.9C20.6875 17.1167 19.975 18.175 19.075 19.075C18.175 19.975 17.1167 20.6875 15.9 21.2125C14.6833 21.7375 13.3833 22 12 22Z"
              fill="currentColor"
            />
          </svg>
        </div>

        <div
          :id="`tooltip-${$attrs.name}`"
          role="tooltip"
          class="tooltip invisible absolute z-10 inline-block rounded-tooltip bg-primary p-[1rem] text-t6 font-light text-white opacity-0 shadow-sm transition-opacity duration-300 dark:bg-white dark:text-primary"
        >
          {{ $attrs.tooltip }}
          <div class="tooltip-arrow" data-popper-arrow></div>
        </div>
      </div>
    </template>
    <!-- @vue-ignore -->
    <template #closeIcon><slot name="closeIcon"></slot></template>
  </FormKit>
</template>

<style lang="scss">
[data-invalid] .formkit-inner {
  @apply ring-2 ring-red-40;
}
[data-invalid][data-type='file'] .formkit-inner {
  @apply ring-2 ring-red-40;
}
</style>
